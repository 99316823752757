@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400&display=swap");

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --main-background: #172a45;
  --secondary: #8892b0;
  --white: ghostwhite;
  --light-secondary: rgb(136, 146, 176);
  --icon-main: rgb(23, 42, 69);
  --icon-secondary: #c0c0c0;
  --uiuc-orange: #e84a27;
  --uiuc-orange-secondary: rgba(232, 74, 39, 0.15);

  /* Company colors */
  --runloop: #118362;
  --runloop-highlight: rgba(17, 131, 98, 0.15);
  --stripe: #6772e5;
  --stripe-highlight: rgba(103, 114, 229, 0.15);
  --airkit: rgb(255, 78, 131);
  --airkit-highlight: rgb(255, 78, 131, 0.15);
  --facebook: #39579c;
  --facebook-highlight: rgba(57, 87, 156, 0.15);
  --salesforce: #17a0db;
  --salesforce-highlight: rgba(23, 160, 219, 0.15);
  --tableau: #4e79a7;
  --tableau-highlight: rgba(78, 121, 167, 0.15);
  --motorola: black;
  --motorola-highlight: rgba(0, 0, 0, 0.15);
  --caterpillar: #d5b60a;
  --caterpillar-highlight: rgba(213, 182, 10, 0.15);
  --ameren: #35962b;
  --ameren-highlight: rgba(53, 150, 43, 0.15);

  /* Pastels */
  --pastel-primary: #defcf9;
  --pastel-primary-highlight: rgba(222, 252, 249, 0.15);
  --pastel-secondary: #cadefc;
  --pastel-secondary-highlight: rgba(202, 222, 252, 0.15);
  --pastel-tertiary: #c3bef0;
  --pastel-teritary-highlight: rgba(195, 190, 240, 0.15);
  --pastel-quaternary: #cca8e9;
  --pastel-quaternary-highlight: rgba(204, 168, 233, 0.15);
}
